var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.props.row["sopImprovementIds"] && _vm.props.row["ibmStepNames"]
        ? _vm._l(_vm.$comm.ibmTagItems(_vm.props.row), function (item, index) {
            return _c(
              "q-chip",
              {
                key: index,
                staticStyle: { "margin-bottom": "4px !important" },
                attrs: {
                  outline: "",
                  square: "",
                  color: item.color,
                  clickable: true,
                },
                on: {
                  click: function ($event) {
                    return _vm.openImprPop(item, _vm.props.row)
                  },
                },
              },
              [
                _vm._v(" " + _vm._s(item.title) + " "),
                _c("c-assess-tooltip", {
                  attrs: {
                    ibmTooltip: item.ibmTooltip,
                    ibmClassCd: item.ibmClassCd,
                  },
                }),
              ],
              1
            )
          })
        : void 0,
      _vm.inputEditable && _vm.props.row.editFlag !== "C"
        ? _c(
            "div",
            [
              _c(
                "q-btn-group",
                { staticClass: "ColumInnerBtnGroup", attrs: { outline: "" } },
                [
                  _c(
                    "q-btn",
                    {
                      staticClass: "ColumInnerBtn",
                      attrs: {
                        icon: "add",
                        color: "red-6",
                        "text-color": "white",
                        align: "center",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.innerBtnClicked(_vm.props, "1")
                        },
                      },
                    },
                    [
                      _c("q-tooltip", [
                        _vm._v(" " + _vm._s(_vm.$label("LBL0001400")) + " "),
                      ]),
                    ],
                    1
                  ),
                  _vm.isImmShow
                    ? _c(
                        "q-btn",
                        {
                          staticClass: "ColumInnerBtn",
                          attrs: {
                            icon: "add",
                            color: "light-blue",
                            "text-color": "white",
                            align: "center",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.innerBtnClicked(_vm.props, "2")
                            },
                          },
                        },
                        [
                          _c("q-tooltip", [
                            _vm._v(
                              " " + _vm._s(_vm.$label("LBL0001401")) + " "
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }